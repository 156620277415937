@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Kanit:wght@500&display=swap');

*{
    box-sizing: border-box;
    font-size: 1.1rem;
}

h1{
    font-family: 'Bebas Neue', sans-serif;
    font-size: 4rem !important;
}

h2, h3{
    font-family: 'Bebas Neue', sans-serif;
    font-size: 3rem !important;
    
}

body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden !important;
}

.cimg{
    width: 100vw;
    height: 30rem;
    object-fit: cover;
}

.f_footer{
    position: fixed;
    bottom: 0;
    width: 100vw;
}

.phonenum{
   text-decoration: none;
   color: aliceblue;
}

.bder{
    border: 5px solid #333 !important;
}

.form-control{
    border: 2px solid #333 !important;
}

