.prod{
    border: 2px solid gainsboro !important;
    border-radius: 1% !important;
}

.big > img{
    height: 15rem;
    object-fit: cover !important;
    border-radius: 0% !important;
}

