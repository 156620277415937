*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #141616;
  background-color: #e6e9ea;
  font-size: 28px;
}

h1 {
  font-size: 110px;
  margin-bottom: 1rem;
}

.full-screen-section {
  height: 100vh;
  text-align: center;
  padding: 1rem;
  position: relative;
}

.top-section .left,
.top-section .right {
  flex-basis: 0;
  flex-grow: 1;
  padding: 1rem;
  padding-left: 3rem;
}

.top-section {
  display: flex;
  text-align: start;
  padding: 0;
}

.top-section .left {
  background-color: rgb(248, 250, 249, var(--background-opacity));
}

.top-section .right {
  background: linear-gradient(
    210.65deg,
    rgb(152, 157, 157, var(--background-opacity)) 0%,
    rgb(189, 194, 194, var(--background-opacity)) 100%
  );
}

.imgs > .top-section-img {
  /* (oldVal - oldMin) * newRange / oldRange + newMin */
  --value: min(var(--scroll), 30) * 50 / 30;
  width: calc(50vw + 1vw * var(--value));
  transform: translateY(calc(50% - 1% * var(--value)));
}

@media (width <= 1000px) {
  .top-section .right {
    display: none;
  }

  .top-section .left {
    text-align: center;
  }

  body {
    font-size: 24px;
  }

  h1 {
    font-size: 72px;
  }

  .imgs > .top-section-img {
    width: 100vw;
  }
}

:root {
  --scroll: 0;
}

.top-section {
  position: sticky;
  top: 0;
  /* (oldVal - oldMin) * newRange / oldRange + newMin */
  translate: 0 calc(-1% * (max(var(--scroll), 25) - 25) * 100 / 75);
  --background-opacity: calc(100% - 1% * min(var(--scroll), 30) * 100 / 30);
}

.first-main-section {
  padding-top: 10vh;
}

.imgs > * {
  position: fixed;
  width: 100vw;
  bottom: 0;
  z-index: 10;
  translate: 0 100%;
  transition: translate 250ms ease-in-out;
}

.imgs > .show {
  translate: 0 0;
}

[data-img-to-show] {
  position: absolute;
  top: 20%;
}

.area{
  background-color:#333 !important;
  color: whitesmoke;
  padding: .5rem;
  margin: 1rem 0;
}

.image-limit {
  max-width: 50%;
  min-width: 20rem;
  height: auto;
}

.red{
  color: red;
  font-weight: bold;
  font-size: 1.2rem;
}

li{
  margin: 1rem;
}

.image-container {
  min-height: 100%; /* Set a minimum height for the container */
  display: flex;
  align-items: center;
  justify-content: center;
}

.bold{
  font-weight: bold;
}