.crd{
    border: 2px solid maroon !important;
    border-radius: 1% !important;
}
.card:hover{
    box-shadow: 2px 2px 5px gray;
    cursor: pointer;
}
.card>img {
    height: 20rem;
    object-fit: contain;
    border-radius: 0%;
}
.card-title{
    text-align: center;
}