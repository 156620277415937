
.flex{
   display: flex;
    gap: var(--gap,1rem)
}
.primary-header {
    align-items: center;
    justify-content: space-between;
}
  
  .logo {
    width: 6rem;
    margin: 1rem 2rem;
  }
  
  .primary-navigation {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #333;
    backdrop-filter: blur(10rem);
  }
  
  .mobile-nav-toggle {
    display: none;
  }
  

  .nav_item {
    text-decoration: none;
    color: aliceblue;
    font-size: 1.2rem;
    padding: 1rem 0; 
    display: block;
    font-family: 'Bebas Neue', sans-serif;
    font-family: 'Kanit', sans-serif;

  }
  
  .nav_item:hover {
    background-color: #727272;
  }
  
  .active {
    text-decoration: overline;
    font-weight: bold;
    color: crimson;
  }
  


  @media (max-width: 90rem) {
    .primary-header.nav-hidden {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .primary-navigation {
      transform: translateX(100%);
      display: none;
    }
  
    .primary-navigation.visible {
      display: block;
      position: fixed;
      inset: 0 0 0 30%;
      flex-direction: column;
      padding: min(30vw,8rem)2em;
      --gap: 1rem;
      transform: translateX(0);
      transition: transform 250ms ease-in;
      z-index: 999;
    }
  
    .mobile-nav-toggle {
      display: block;
      position: absolute;
      width: 6rem;
      background: url('https://cdn.iconscout.com/icon/free/png-256/free-hamburger-menu-4562823-3856517.png?f=webp');
      background-size: 100%;
      background-repeat: no-repeat;
      border: 0;
      cursor: pointer;
      aspect-ratio: 1;
      top: 1rem;
      right: 2rem;
      z-index: 999;
    }
    .mobile-nav-toggle.nav-open {
      position: fixed;
        background: url('https://cdn.iconscout.com/icon/free/png-256/free-close-145-457663.png?f=webp');
        background-size: 100%;
        background-repeat: no-repeat;
        z-index: 9999;
      }

    .nav_item{
        border-top: 1px solid #ccc;
    }
    
}

@media(min-width:35rem){
    .primary-navigation{
        padding-block: .2rem;
        padding-inline: clamp(1rem,5vw,10rem);
        --gap: clamp(1rem,5vw,5rem)
        
    }
    }

